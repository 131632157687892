<template>
  <div>
    
    <b-modal id="addWatchConfirm" 
             title="Beobachtung hinzugefügt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gespeichert...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <div class="my-4" v-else>
          <p>Sie beobachten nun <strong>{{ actionedFondsName }}</strong> und erhalten E-Mails mit aktuellen Handelsinformationen.</p>
          <p>Sie können nun auch die Fondsliste nach Ihren beobachteten Fonds filtern.</p>
        </div>
      </div>
    </b-modal>
    
    <b-modal id="deleteWatchConfirm"
             title="Beobachtung entfernt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }"> 
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gelöscht...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <p class="my-4" v-else>
          Sie beobachten <strong>{{ actionedFondsName }}</strong> nun nicht mehr und erhalten keine E-Mails mit Handelsinformationen.
        </p>
      </div>
    </b-modal>
    
    <div class="row py-3">
      <div>
        <div v-if="hasKaeufermailError()">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ kaeufermailError() }}</p>
          </div>
        </div>
        <div md="12" class="text-left" v-if="!this.isDefaultSort()">
          <b-badge variant="warning" class="mb-3">
            Sortierung nach {{ getSortObject( form.sortproperty ).displayName }}, {{ form.sortdirection === 'asc' ? 'auf' : 'ab' }}steigend
            <a class="badge-cancel ml-2" @click="resetSort()"><i class="fas fa-times"></i></a>
          </b-badge>
        </div>
        
        <b-table id="kaeufermaillistetable" ref="kaeufermaillistetable"
            bordered
            striped
            responsive="xl"
            :busy="isKaeufermailWaiting"     
            :items="getKaeufermailData"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :key="kmListKey" 
            :tbody-tr-class="rowClass"
            head-variant="dark"
        >
          <template #thead-top="">
            <b-tr variant="dark">
              <b-th style="border-bottom: none" class="col-30-percent">
                <table-header-sort-element 
                    :sortprop="getSortObject( 'Fonds' ).key"
                    :sortdir="getSortObject( 'Fonds' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Fonds
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-10-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'Brief' ).key"
                    :sortdir="getSortObject( 'Brief' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Verkauf
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-15-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'Nominalbeteiligung' ).key"
                    :sortdir="getSortObject( 'Nominalbeteiligung' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Volumen
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-5-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'Teilausfuehrbarkeit' ).key"
                    :sortdir="getSortObject( 'Teilausfuehrbarkeit' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  <span title="Teilausführbar">T/A</span>
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-15-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'LetzterKurs' ).key"
                    :sortdir="getSortObject( 'LetzterKurs' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  <span title="Letzter Kurs innerhalb der letzten 12 Monate">Letzter Kurs</span>
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-15-percent">
                <table-header-sort-element
                    :sortprop="getSortObject( 'Beteiligungstyp' ).key"
                    :sortdir="getSortObject( 'Beteiligungstyp' ).sortdir"
                    v-on:sort-clicked="onSort"
                >
                  Beteiligungstyp
                </table-header-sort-element>
              </b-th>
              <b-th style="border-bottom: none" class="col-10-percent">
                Aktionen
              </b-th>
            </b-tr>
          </template>
          
          <template #cell(issuerandfonds)="data">
            <div class="text-left">
              <strong>
                <router-link :to="{name: 'fondsdetails', params: {client: client, id: data.item.trancheId}}">
                  {{ data.item.anbietername }} - {{ fondsNameWithZusatz( data.item ) }}
                </router-link>
              </strong>
            </div>
          </template>

          <template #cell(sellrate)="data">
            <div class="text-left">
              {{ formatPercent( data.item.limit ) }}<span v-if="data.item.limit">%</span><span v-else>&nbsp;</span>
            </div>
          </template>

          <template #cell(volume)="data">
            <div class="text-left">
              <span v-if="data.item.nominale">{{data.item.nominale.waehrung}} {{ formatPrice( data.item.nominale.betrag ) }}</span>
            </div>
          </template>

          <template #cell(teilausfuehrbar)="data">
            <span v-if="data.item.teilausfuehrbar">
              <i class="fas fa-check-circle"></i> 
            </span>
          </template>

          <template #cell(lastkurs)="data">
            <div class="text-left">
              {{ formatPercent( data.item.lastkurs ) }}<span v-if="data.item.lastkurs">%</span><span v-else>&nbsp;</span>
            </div>
          </template>

          <template #cell(beteiligungstyp)="data">
            <div class="text-left">
              {{ data.item.beteiligungstyp }}
            </div>
          </template>

          <template #cell(action)="data">
            <div class="btn-group" role="group" aria-label="Aktionen">
              <div>
                <a @click="wizardLink(`${data.item.trancheId}`, 'KAUF')" class="btn-trade pointer px-2 btn-buy"><span v-if="!isMeinHandel">K</span></a>
              </div>

              <a v-if="hasToken() && !isTrancheWatched( `${data.item.trancheId}` )" 
                 @click="watchTranche( `${data.item.trancheId}`, `${ getFondsName( data.item.anbietername, data.item.fondsname, data.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                <i class="fa fa-eye fa-lg" aria-hidden="true" :title="beobachtenWord"></i>
              </a>
              <a v-if="hasToken() && isTrancheWatched( `${data.item.trancheId}` )" 
                 @click="unwatchTranche( `${data.item.trancheId}`, `${ getFondsName( data.item.anbietername, data.item.fondsname, data.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                <i class="fas fa-eye-slash fa-lg" aria-hidden="true" :title="beobachtenDeleteWord"></i>
              </a>
            </div>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>
        </b-table>
        
      </div>
    </div>
    
  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import TableHeaderSortElement from "@/components/sorting/TableHeaderSortElement";
import { mapGetters } from "vuex";
import router from "../../router";

export default {
  name: "KaeufermailTable",
  components: {TableHeaderSortElement},
  data: () => ({
    actionedFondsName: null,
    currentPage: 1,
    perPage: 250,
    kmListKey: 0,
    form: {
      sortproperty: 'Fonds',
      sortdirection: 'asc',
    },
    sortoptions: [
      { key: 'Fonds', name: 'Fonds', sortdir: 'asc', displayName: 'Fonds' },
      { key: 'Brief', name: 'Brief', sortdir: 'none', displayName: 'Verkauf' },
      { key: 'Nominalbeteiligung', name: 'Nominalbeteiligung', sortdir: 'none', displayName: 'Volumen' },        
      { key: 'Teilausfuehrbarkeit', name: 'Teilausfuehrbarkeit', sortdir: 'none', displayName: 'T/A' },
      { key: 'Beteiligungstyp', name: 'Beteiligungstyp', sortdir: 'none', displayName: 'Beteiligungstyp' },
      { key: 'LetzterKurs', name: 'LetzterKurs', sortdir: 'none', displayName: 'Letzter Kurs' },
    ],
  }),
  computed: {
    ...mapGetters('kaeufermail', ['getKaeufermailData','isKaeufermailWaiting']),
    kaufWord() { return "Kaufen" },
    beobachtenWord() { return "Beobachten" },
    beobachtenDeleteWord() { return "Beobachtung löschen" },
    fields() {
      return [
        { key: 'issuerandfonds', label: 'Fondsgesellschaft', tdClass: ["col-30-percent"], thClass: ["hidden-header"] },
        { key: 'sellrate', label: 'Verkauf', thClass: ["hidden-header"] },
        { key: 'volume', label: 'Volumen', thClass: ["hidden-header"] },
        { key: 'teilausfuehrbar', label: 'T/A', thClass: ["hidden-header"] },
        { key: 'lastkurs', label: 'Letzter Kurs', thClass: ["hidden-header"] },
        { key: 'beteiligungstyp', label: 'Beteiligungstyp', thClass: ["hidden-header"] },
        { key: 'action', label: '', tdClass: ["align-content-center"], thClass: ["hidden-header"] },
      ] 
    },
    token() {
      return this.$store.getters["auth/getToken"]
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
    isMeinHandel() {
      return this.client === 'meinhandel';
    },
    isWatchLoading() {
      return this.$store.getters["watches/isWaiting"];
    },
    hasWatchError() {
      var watchStatus = this.$store.getters["watches/watchesStatus"];
      return !(watchStatus === undefined || watchStatus === null || watchStatus === '' || watchStatus === "" )
    },
  },
  methods: {
    getFondsName( anbietername, fondsname, fondsnamezusatz ) {
      let displayname;
      if ( fondsname.startsWith( anbietername ) ) {
        displayname = fondsname;
      }
      else {
        displayname = anbietername + ' ' + fondsname;
      }
      if ( fondsnamezusatz ) {
        displayname = displayname + ' ' + fondsnamezusatz;
      }
      return displayname;
    },
    hasKaeufermailError() {
      var kmStatus = this.kaeufermailError();
      return !(kmStatus === undefined || kmStatus === null || kmStatus === '' || kmStatus === "" )
    },
    kaeufermailError() {
      return this.$store.getters["kaeufermail/kaeufermailStatus"];
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return
      }
      if ( item.vorkaufsrecht ) {
        return 'vorkaufsrecht'
      }
    },
    incKey() {
      this.kmListKey++;  
    },
    forceReload() {
      this.$store.dispatch('kaeufermail/resetKaeufermailData');
      this.$store.dispatch('kaeufermail/setKmWaiting');
      const reqParams = {
        page: 0,
        size: 999,
        sort: this.form.sortproperty ? this.form.sortproperty + ',' + this.form.sortdirection : '',
      };
      this.$store.dispatch('kaeufermail/loadKaeufermail',reqParams);
      this.incKey();
    },
    kaufUrl( tid ) {
      return this.$store.getters.getAllEnvis.URL_EXT_SYSTEM + "/" + this.$store.getters["getSubsite"] + "/p_auftrag_anlegen?auftragType=KAUF&tid=" + tid;
    },
    wizardLink( tid, type) {
      if(this.token) {
        router.push({name: 'AuftragsVerwaltung', params: {client: this.$store.getters['getSubsite']}, query: {auftragType: type, tid: tid}});
      } else {
        const a = 'auftragsverwaltung';
        const b = type;
        const c = tid;
        router.push({name: 'login', params: {client: this.$store.getters['getSubsite']}, query: { a, b, c }});
      }
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz; 
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      this.currentPage = 1;
      this.forceReload();
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey || sortopt.name === sortkey ) {
          return sortopt;
        }
      }  
      return [];
    },
    onSort: function( sortpropkey, sortdir ) {
      var sortopt = this.getSortObject( sortpropkey );
      var newSortDir = sortdir !== 'none' ? sortdir : 'asc';
      this.form.sortproperty = sortopt.name;
      this.form.sortdirection = sortdir;
      for( var s of this.sortoptions ) {
        if( s.key === sortpropkey ) {
          s.sortdir = newSortDir;
        }
        else {
          s.sortdir = 'none';
        }
      }
      let event = null;
      this.onSubmit( event );
    },
    isDefaultSort() {
      return this.form.sortproperty === 'Fonds' && this.form.sortdirection === 'asc';
    },
    resetSort() {
      this.form.sortproperty = 'Fonds';
      this.form.sortdirection = 'asc';
      this.onSort( this.form.sortproperty, this.form.sortdirection );
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    watchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/addWatch", payload );
      this.$bvModal.show( 'addWatchConfirm' );
    },
    unwatchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/removeWatch", payload );
      this.$bvModal.show( 'deleteWatchConfirm' );
    },
    isTrancheWatched( trancheId ) {
      return this.$store.getters["watches/isWatched"](trancheId);
    },
    fetchWatches() {
      if ( this.hasToken() ) {
        this.$store.dispatch('watches/loadWatches')
      }
    },
  },
  mixins: [priceMixin,percentMixin],
  created() {
    const reqParams = {
      page: 0,
      size: 999,
      sort: 'Fonds,asc',
    };
    this.$store.dispatch('kaeufermail/loadKaeufermail',reqParams);
  },
  mounted() {
    this.fetchWatches();
    this.$nextTick(function () {
      if ( !this.isKaeufermailWaiting ) {
        this.kmListKey++;
      }
    });
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if ( "deleteWatchConfirm" === modalId || "addWatchConfirm" === modalId ) {
        this.forceReload();
      }
    })
  },
  
  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style type="scss" scoped>
</style>