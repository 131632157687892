<template>
  <div>

    <b-modal id="addWatchConfirm"
             title="Beobachtung hinzugefügt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gespeichert...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <div class="my-4" v-else>
          <p>Sie beobachten nun <strong>{{ actionedFondsName }}</strong> und erhalten E-Mails mit aktuellen Handelsinformationen.</p>
          <p>Sie können nun auch die Fondsliste nach Ihren beobachteten Fonds filtern.</p>
        </div>
      </div>
    </b-modal>

    <b-modal id="deleteWatchConfirm"
             title="Beobachtung entfernt"
             header-bg-variant="dark"
             :hide-footer="isWatchLoading">
      <template #modal-footer="{ ok }">
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">OK</b-button>
      </template>
      <div class="text-center my-2" v-if="isWatchLoading">
        <b-spinner class="align-middle"></b-spinner>
        <strong class="ml-2">Die Beobachtung wird gelöscht...</strong>
      </div>
      <div v-else>
        <p class="my-4" v-if="hasWatchError">Beim Speichern ist ein Fehler aufgetreten.</p>
        <p class="my-4" v-else>
          Sie beobachten <strong>{{ actionedFondsName }}</strong> nun nicht mehr und erhalten keine E-Mails mit Handelsinformationen.
        </p>
      </div>
    </b-modal>

    <div class="row py-3">
      <div class="col-sm-12">

        <div v-if="hasKaeufermailError()">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ kaeufermailError() }}</p>
          </div>
        </div>

        <b-col class="text-left pl-0 pr-0">
          <b-collapse v-model="sortFilterBar">
            <b-button class="sort-tile mb-3" @click="toggleSortLayer">
              <i class="fa fa-sort-alt"></i> Sortieren<br />
              <span class="currentSort">
                {{ getSortObject( form.sortproperty ).displayName }}
                <i class="fas fa-long-arrow-alt-down" v-if="currentSortDirIsDesc()"></i>
                <i class="fas fa-long-arrow-alt-up" v-else></i>
                <!-- a class="ml-2" @click="resetSort()"><i class="fas fa-times"></i></a -->
              </span>
            </b-button>
          </b-collapse>
        </b-col>

        <b-collapse id="mobile-sort" class="pt-3" v-model="sortToggle">
          <b-row>
            <b-col class="text-left">
              <ul>
                <li v-on:click="onSort('Fonds');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Fonds
                </li>
                <li v-on:click="onSort('Brief');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Verkauf
                </li>
                <li v-on:click="onSort('Nominalbeteiligung');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Volumen
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-collapse>

        <b-table id="kaeufermaillistetable" ref="kaeufermaillistetable"
                 class="mobile"
                 bordered
                 striped
                 responsive="xl"
                 :busy="isKaeufermailWaiting"
                 :items="getKaeufermailData"
                 :fields="fields"
                 :per-page="perPage"
                 :current-page="currentPage"
                 :key="kmListKey"
                 :tbody-tr-class="rowClass"
                 head-variant="dark"
        >
          <template #thead-top="">
            <b-tr variant="dark">
              <b-th class="text-left col-45-percent">
                Fonds
              </b-th>
              <b-th  class="col-20-percent">
                Verkauf
              </b-th>
              <b-th class="col-25-percent">
                Volumen
              </b-th>
              <b-th class="col-10-percent">
                &nbsp;
              </b-th>
            </b-tr>
          </template>

          <template #cell(issuerandfonds)="data">
            <div class="text-left">
              <strong>
                <router-link :to="{name: 'fondsdetails', params: {client: client, id: data.item.trancheId}}">
                  {{ data.item.anbietername }} - {{ fondsNameWithZusatz( data.item ) }}
                </router-link>
              </strong>
            </div>
          </template>

          <template #cell(sellrate)="data">
            <div class="text-center">
              {{ formatPercent( data.item.limit ) }}<span v-if="data.item.limit">%</span><span v-else>&nbsp;</span>
            </div>
          </template>

          <template #cell(volume)="data">
            <div class="text-center">
              <span style="word-wrap:break-word;" v-if="data.item.nominale">{{data.item.nominale.waehrung}} {{ formatPrice( data.item.nominale.betrag ) }}</span>
            </div>
          </template>

          <template #cell(show_details)="row">
            <i class="fas fa-plus activeCursor" @click="row.toggleDetails"></i>
          </template>

          <template #row-details="row">
            <table class="detailTable">
              <tr>
                <td v-if="row.item.teilausfuehrbar" class="text-left" colspan="2"><span class="pr-4"><strong>T/A:</strong></span> <i class="fas fa-check-circle"></i></td>
              </tr>
              <tr>
                <td class="text-left" colspan="2"><strong>Letzter Kurs:</strong> {{ formatPercent( row.item.lastkurs ) }}<span v-if="row.item.lastkurs">%</span><span v-else>&nbsp;</span></td>
              </tr>
              <tr>
                <td class="text-left" colspan="2"><strong>Beteiligungstyp:</strong> {{ row.item.beteiligungstyp }}</td>
              </tr>
              <tr>
                <td class="text-left">
                  <span class="pr-4"><strong>Aktionen:</strong></span>
                  <div class="btn-group" role="group" aria-label="Aktionen">
                    <div>
                      <a @click="wizardLink(`${row.item.trancheId}`, 'KAUF')" class="btn-trade pointer px-2 btn-buy"><span v-if="!isMeinHandel">K</span></a>
                    </div>
                    <a v-if="hasToken() && !isTrancheWatched( `${row.item.trancheId}` )"
                       @click="watchTranche( `${row.item.trancheId}`, `${ getFondsName( row.item.anbietername, row.item.fondsname, row.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                      <i class="fa fa-eye fa-lg" aria-hidden="true" :title="beobachtenWord"></i>
                    </a>
                    <a v-if="hasToken() && isTrancheWatched( `${row.item.trancheId}` )"
                       @click="unwatchTranche( `${row.item.trancheId}`, `${ getFondsName( row.item.anbietername, row.item.fondsname, row.item.fondsnamezusatz ) }` )" class="pt-2 pl-1 watchLink">
                      <i class="fas fa-eye-slash fa-lg" aria-hidden="true" :title="beobachtenDeleteWord"></i>
                    </a>
                  </div>
                  <b-button size="sm" class="closeButton float-right" @click="row.toggleDetails">Schliessen</b-button>
                </td>
              </tr>
            </table>
          </template>

          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>
        </b-table>

      </div>
    </div>

  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import { mapGetters } from "vuex";
import router from "../../router";

export default {
  name: "KaeufermailTableMobile",
  components: {},
  data: () => ({
    actionedFondsName: null,
    currentPage: 1,
    perPage: 250,
    kmListKey: 0,
    form: {
      sortproperty: 'Fonds',
      sortdirection: 'asc',
    },
    sortFilterBar: true,
    sortToggle: false,
    sortoptions: [
      { key: 'Fonds', name: 'Fonds', sortdir: 'asc', displayName: 'Fonds' },
      { key: 'Brief', name: 'Brief', sortdir: 'none', displayName: 'Verkauf' },
      { key: 'Nominalbeteiligung', name: 'Nominalbeteiligung', sortdir: 'none', displayName: 'Volumen' },
    ],
  }),
  computed: {
    ...mapGetters('kaeufermail', ['getKaeufermailData','isKaeufermailWaiting']),
    kaufWord() { return "Kaufen" },
    beobachtenWord() { return "Beobachten" },
    beobachtenDeleteWord() { return "Beobachtung löschen" },
    fields() {
      return [
        { key: 'issuerandfonds', label: 'Fondsgesellschaft', tdClass: ["col-30-percent"], thClass: ["hidden-header"] },
        { key: 'sellrate', label: 'Verkauf', thClass: ["hidden-header"] },
        { key: 'volume', label: 'Volumen', thClass: ["hidden-header"] },
        { key: 'show_details', label: 'Details', thClass: ["hidden-header"] },
      ]
    },
    token() {
      return this.$store.getters["auth/getToken"]
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
    isMeinHandel() {
      return this.client === 'meinhandel';
    },
    isWatchLoading() {
      return this.$store.getters["watches/isWaiting"];
    },
    hasWatchError() {
      var watchStatus = this.$store.getters["watches/watchesStatus"];
      return !(watchStatus === undefined || watchStatus === null || watchStatus === '' || watchStatus === "" )
    },
  },
  methods: {
    getFondsName( anbietername, fondsname, fondsnamezusatz ) {
      let displayname;
      if ( fondsname.startsWith( anbietername ) ) {
        displayname = fondsname;
      }
      else {
        displayname = anbietername + ' ' + fondsname;
      }
      if ( fondsnamezusatz ) {
        displayname = displayname + ' ' + fondsnamezusatz;
      }
      return displayname;
    },
    hasKaeufermailError() {
      var kmStatus = this.kaeufermailError();
      return !(kmStatus === undefined || kmStatus === null || kmStatus === '' || kmStatus === "" )
    },
    kaeufermailError() {
      return this.$store.getters["kaeufermail/kaeufermailStatus"];
    },
    rowClass(item, type) {
      if (!item || type !== 'row') {
        return
      }
      if ( item.vorkaufsrecht ) {
        return 'vorkaufsrecht'
      }
    },
    incKey() {
      this.kmListKey++;
    },
    forceReload() {
      this.$store.dispatch('kaeufermail/resetKaeufermailData');
      this.$store.dispatch('kaeufermail/setKmWaiting');
      const reqParams = {
        page: 0,
        size: 999,
        sort: this.form.sortproperty ? this.form.sortproperty + ',' + this.form.sortdirection : '',
      };
      this.$store.dispatch('kaeufermail/loadKaeufermail',reqParams);
      this.incKey();
    },
    kaufUrl( tid ) {
      return this.$store.getters.getAllEnvis.URL_EXT_SYSTEM + "/" + this.$store.getters["getSubsite"] + "/p_auftrag_anlegen?auftragType=KAUF&tid=" + tid;
    },
    wizardLink( tid, type) {
      if(this.token) {
        router.push({name: 'AuftragsVerwaltung', params: {client: this.$store.getters['getSubsite']}, query: {auftragType: type, tid: tid}});
      } else {
        const a = 'auftragsverwaltung';
        const b = type;
        const c = tid;
        router.push({name: 'login', params: {client: this.$store.getters['getSubsite']}, query: { a, b, c }});
      }
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz;
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      this.currentPage = 1;
      this.forceReload();
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey || sortopt.name === sortkey ) {
          return sortopt;
        }
      }
      return [];
    },
    onSort: function( sortpropkey ) {
      var sortopt = this.getSortObject( sortpropkey );
      // Get current sortdir and then toggle
      if ( 'none' === sortopt.sortdir || 'desc' === sortopt.sortdir ) {
        sortopt.sortdir = 'asc';
      }
      else if ( 'asc' === sortopt.sortdir ) {
        sortopt.sortdir = 'desc';
      }

      var newSort = sortopt.sortdir;

      // reset all others
      for( var sortopt2 of this.sortoptions ) {
        if( sortopt2.key === sortpropkey ) {
          // do nothing;
        }
        else {
          sortopt2.sortdir = 'none';
        }
      }

      this.form.sortproperty = sortpropkey;
      this.form.sortdirection = newSort;

      let event = null;
      this.onSubmit( event );
    },
    isDefaultSort() {
      return this.form.sortproperty === 'Fonds' && this.form.sortdirection === 'asc';
    },
    resetSort() {
      this.form.sortproperty = 'Fonds';
      this.form.sortdirection = 'asc';
      this.onSort( this.form.sortproperty, this.form.sortdirection );
    },
    currentSortDirIsDesc() {
      var currentSortDir = 'desc';
      for( var sortopt of this.sortoptions ) {
        if( sortopt.sortdir !== 'none' ) {
          currentSortDir = sortopt.sortdir;
        }
      }
      return currentSortDir === 'desc';
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    watchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/addWatch", payload );
      this.$bvModal.show( 'addWatchConfirm' );
    },
    unwatchTranche( trancheId, fondsname ) {
      this.actionedFondsName = fondsname;
      const payload = {
        trancheId: trancheId,
      }
      this.$store.dispatch("watches/removeWatch", payload );
      this.$bvModal.show( 'deleteWatchConfirm' );
    },
    isTrancheWatched( trancheId ) {
      return this.$store.getters["watches/isWatched"](trancheId);
    },
    fetchWatches() {
      if ( this.hasToken() ) {
        this.$store.dispatch('watches/loadWatches')
      }
    },
    toggleSortFilterBar() {
      if(!this.sortFilterBar) {
        this.sortFilterBar = true;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }
    },
    toggleSortLayer() {
      if(this.sortToggle) {
        this.sortToggle = false;
        return;
      }

      if(this.filterToggle) {
        this.filterToggle = false;
      }

      if(this.sortFilterBar) {
        this.sortFilterBar = false;
      }

      this.sortToggle = true;
    },
  },
  mixins: [priceMixin,percentMixin],
  created() {
    const reqParams = {
      page: 0,
      size: 999,
      sort: 'Fonds,asc',
    };
    this.$store.dispatch('kaeufermail/loadKaeufermail',reqParams);
  },
  mounted() {
    this.fetchWatches();
    this.$nextTick(function () {
      if ( !this.isKaeufermailWaiting ) {
        this.kmListKey++;
      }
    });
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if ( "deleteWatchConfirm" === modalId || "addWatchConfirm" === modalId ) {
        this.forceReload();
      }
    })
  },

  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style type="scss">

  .noMobilePadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

  .activeCursor {
    cursor: pointer;
  }

  #kaeufermaillistetable.b-table tbody > tr > td:last-child {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }

  .b-table-details > td {
    padding: 0 !important;
  }

  .b-table-details .closeButton {
    white-space: nowrap;
  }

  .detailTable {
    width: 100%;
  }

  .detailTable tr {
    background: none !important;
  }

</style>