<template>
  <div>
    <section>
      <Hero>
        <div>
          <div class="container">
            <HeadText :titletext=headText :subtext="this.getHeadSubText()" :key="kmDateKey"></HeadText>
          </div>
          <div class="container bg-light pt-3 px-3">                      
            <b-row>
              <b-col md="6" class="text-left">
                <p>
                  Wichtiger Hinweis: Bitte beachten Sie, dass wir für das heutige Bietverfahren ausschließlich bis 14 Uhr 
                  Gebote annehmen können. Alle nach 14 Uhr abgegebenen Gebote finden erst am nächsten Handelstag Berücksichtigung.
                </p>
                <p>
                  Sie wollen täglich die aktuellen Verkaufsangebote per E-Mail? Klicken Sie
                  <router-link to="/infoservices">hier!</router-link>
                </p>
              </b-col>
              <b-col md="6" class="text-left">
                <FdbContact></FdbContact>
                <p class="p-3 vorkaufsrecht">
                  Bei farblich hervorgehobenen Fondsgesellschaften existiert ein Vorkaufsrecht.
                </p>
              </b-col>
            </b-row>
          </div>
          <div class="container bg-light">
            <div class="container bg-light">
              <KaeufermailTableMobile v-if="mobileViewport"></KaeufermailTableMobile>
              <KaeufermailTable v-else></KaeufermailTable>
            </div>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FdbContact from "@/components/FdbContact";
import KaeufermailTable from "@/components/auftrag/KaeufermailTable";
import KaeufermailTableMobile from "@/components/auftrag/KaeufermailTableMobile";
import store from "@/store";

export default {
  name: "Kaeufermail",
  components: {Hero, HeadText, FdbContact, KaeufermailTable, KaeufermailTableMobile},
  metaInfo() {
    let rel = '';
    let currentUrl = '';
    if(this.client !== 'meinzweitmarkt') {
      rel = 'canonical';
      currentUrl = window.location.href.replace(this.client, 'meinzweitmarkt');
    }
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
      link: [
        {
          rel: rel,
          href: currentUrl,
        }
      ]
    }
  },
  data() {
    return {
      kmDateKey: 0,
    };
  },
  computed: {
    client() {
      return this.$route?.params?.client
    },
    isMeinHandel() {
      return this.client === 'meinhandel';
    },     
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    headText() {
      return "Käufermail";
    },
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    kmDateLoaded() {
      var d = this.localGetLastKaeufermailDate();
      return d && d !== '';
    },
    localGetLastKaeufermailDate() {
      return this.$store.getters["kaeufermail/getLastKaeufermailDate"]
    },
    getHeadSubText() {
      if ( !this.kmDateLoaded() ) {
        return "Die Daten werden geladen..."
      }
      return "Neue Verkaufsangebote vom " + this.localGetLastKaeufermailDate();
    },
  },
  created() {
    store.dispatch('kaeufermail/loadKaeufermailDate');
  },
  mounted() {
    this.$nextTick(function () {
      if ( this.kmDateLoaded() ) {
        this.kmDateKey = 1;
      }
    });
  }
}
</script>

<style scoped>

</style>